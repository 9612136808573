import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { DevicesIcon } from '@app/components/icons/DevicesIcons';
import PageSection from '@app/components/layout/PageSection';

type PromoBenefitsSectionProps = {
  primaryColor: string;
};

const PromoBenefitsSection = ({ primaryColor }: PromoBenefitsSectionProps) => {
  const { t } = useTranslation('promo');
  return (
    <Container primaryColor={primaryColor}>
      <PageSection>
        <BenefitWrapper>
          <Benefit>
            <Title>{t('promo:promo.benefits.watch_great_cinema')}</Title>
            <Body>{t('promo:promo.benefits.watch_great_cinema_body')}</Body>
          </Benefit>
        </BenefitWrapper>
        <BenefitWrapper>
          <Benefit>
            <DevicesIconContainer>
              <DevicesIcon width="100%" color={primaryColor} />
            </DevicesIconContainer>
            <Title>{t('promo:promo.benefits.anytime_anywhere')}</Title>
            <Body>{t('promo:promo.benefits.anytime_anywhere_body')}</Body>
          </Benefit>
        </BenefitWrapper>
      </PageSection>
    </Container>
  );
};

type ContainerProps = {
  primaryColor: string;
};

const Container = styled.div<ContainerProps>`
  text-align: center;
  color: ${props => props.primaryColor};
`;

// extra wrapper required to fix ie11 flex bug
// https://github.com/philipwalton/flexbugs/blob/master/README.md#flexbug-3
const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Benefit = styled.section`
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    width: 340px;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 560px;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 12px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 36px;
    max-width: 520px;
  }
`;

const Body = styled.p`
  font-size: 22px;
  line-height: 1.15;
  width: 100%;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 28px;
  }
`;

const DevicesIconContainer = styled.div`
  width: 300px;
  margin-bottom: 40px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 430px;
  }
`;

export default PromoBenefitsSection;
