import styled from '@emotion/styled';

import ResponsiveBackgroundImage from '@app/components/responsive-images/ResponsiveBackgroundImage';

type PromoBackgroundImageProps = {
  backgroundImg: string;
  backgroundColor?: string;
  darkenByOpacity?: string;
  darkenImage?: boolean;
};

const PromoBackgroundImage = ({
  backgroundImg,
  backgroundColor,
  darkenImage = false,
  darkenByOpacity = '0',
}: PromoBackgroundImageProps) => (
  <Container>
    <ResponsiveBackgroundImage
      imageHeight="100%"
      backgroundColor={`#${backgroundColor}`}
      imageUrl={backgroundImg}
      darkenImage={darkenImage}
      darkenByOpacity={darkenByOpacity}
    />
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
`;

export default PromoBackgroundImage;
