import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { initialiseTrendingFilms } from '@app/services/page-initialisation/splash';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetPromoNowShowingFilms = () => {
  const [isError, setIsError] = useState(false);
  const [nowShowingFilms, setNowShowingFilms] = useState([]);

  const { country, httpContext } = useAppSelector(
    state => ({
      country: state.user.geoLocation,
      httpContext: state.appState.httpContext,
    }),
    shallowEqual,
  );

  const doGetFilms = async () => {
    try {
      const films = await initialiseTrendingFilms(httpContext, country);

      setNowShowingFilms(films);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    doGetFilms();
  }, []);

  return [nowShowingFilms, isError] as const;
};

export default useGetPromoNowShowingFilms;
