import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import {
  ScrollArrowIcon,
  SelectInputArrowIcon,
} from '@app/components/icons/ArrowIcons';

const scrollDown = () =>
  window.scrollBy({
    top: window.innerHeight,
    behavior: 'smooth',
  });

type PromoScrollDownArrowProps = {
  showSmallArrowAndCopy: boolean;
  showLargeArrow: boolean;
  primaryColor: string;
};

const PromoScrollDownArrow = ({
  showSmallArrowAndCopy,
  showLargeArrow,
  primaryColor,
}: PromoScrollDownArrowProps) => {
  const { t } = useTranslation('promo');
  return (
    <Container primaryColor={primaryColor}>
      <Button onClick={scrollDown}>
        <TranslateContainer>
          <SmallArrowAndCopyContainer show={showSmallArrowAndCopy}>
            <SelectInputArrowIcon color={primaryColor} width="36px" />
            <Copy>{t('promo:promo.learn_more')}</Copy>
          </SmallArrowAndCopyContainer>
          <LargeArrowContainer show={showLargeArrow}>
            <ScrollArrowIcon color={primaryColor} width="15px" />
          </LargeArrowContainer>
        </TranslateContainer>
      </Button>
    </Container>
  );
};

type ContainerProps = {
  primaryColor: string;
};

const Container = styled.div<ContainerProps>`
  color: ${props => props.primaryColor};

  @media (max-width: ${props => props.theme.mqNew.tablet}),
    (max-height: 740px) {
    display: none;
  }
`;

const TranslateContainer = styled.div`
  transition: transform 0.6s;
  position: relative;
`;

const Button = styled(UnstyledButton)`
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);

  &:hover ${TranslateContainer} {
    transform: translateY(5px);
  }
`;

type ArrowContainerProps = {
  show: boolean;
};

const SmallArrowAndCopyContainer = styled.div<ArrowContainerProps>`
  display: flex;
  align-items: center;
  transition: opacity 0.6s;
  opacity: ${props => (props.show ? '1' : '0')};
`;

const LargeArrowContainer = styled.div<ArrowContainerProps>`
  position: absolute;
  top: -8px; /* compensate for displacement due to rotation */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.6s;
  opacity: ${props => (props.show ? '1' : '0')};
`;

const Copy = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin-left: 6px;
`;

export default PromoScrollDownArrow;
