import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

import { SpecialPromoSplashPage } from '@app/api/resources/Promo';

import { PROMO_DEFAULT_BG_IMAGE } from '@app/services/promo-utils';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import LocaleNoticeContainer from '@app/components/banners/LocaleNoticeContainer';
import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';
import PromoBackgroundImage from '@app/components/promo/PromoBackgroundImage';

type PromoHeaderProps = {
  showSecondaryContent: boolean;
  primaryColor: string;
  specialPromoSplashPage?: SpecialPromoSplashPage;
  children?: ReactNode;
  secondaryContentCopy?: string;
};

const PromoHeader = ({
  specialPromoSplashPage,
  showSecondaryContent,
  primaryColor,
  children = null,
  secondaryContentCopy = '',
}: PromoHeaderProps) => {
  const {
    background_average_colour_hex,
    logo: partnerLogo,
    background_image,
  } = specialPromoSplashPage || {};

  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);

  const logo = (
    <MubiLogoIcon
      width="90px"
      logoColor={primaryColor}
      dotsColor={primaryColor}
    />
  );
  const logoLink = isAuthenticated ? (
    <Link href="/showing">{logo}</Link>
  ) : (
    <Link href="/">{logo}</Link>
  );

  return (
    <Container primaryColor={primaryColor}>
      <NavFixed>
        <DesktopLocaleNotice>
          <LocaleNoticeContainer withFixedPosition />
        </DesktopLocaleNotice>
        {showSecondaryContent && (
          <PromoBackgroundImage
            backgroundImg={background_image || PROMO_DEFAULT_BG_IMAGE}
            backgroundColor={background_average_colour_hex}
          />
        )}
        <PageSection>
          <Content showSecondaryContent={showSecondaryContent}>
            <CSSTransition
              in={showSecondaryContent}
              appear={showSecondaryContent}
              classNames="appear"
              timeout={600}
            >
              <MubiLogoFixed>{logoLink}</MubiLogoFixed>
            </CSSTransition>
            <CSSTransition
              in={showSecondaryContent}
              appear={showSecondaryContent}
              classNames="appear"
              timeout={600}
            >
              <SecondaryContent>
                <PartnerLogoContainer>
                  {partnerLogo && (
                    <PartnerLogo
                      src={`${partnerLogo}?size=x140`}
                      alt={specialPromoSplashPage.partner_name}
                      loading="eager"
                    />
                  )}
                </PartnerLogoContainer>
                <SecondaryContentRight>
                  {secondaryContentCopy && <Copy>{secondaryContentCopy}</Copy>}
                  {children}
                </SecondaryContentRight>
              </SecondaryContent>
            </CSSTransition>
          </Content>
        </PageSection>
      </NavFixed>
      <NavMobileScrollable>
        <MobileLocaleNotice>
          <LocaleNoticeContainer withFixedPosition />
        </MobileLocaleNotice>
        <PageSection>
          <Content>{logoLink}</Content>
        </PageSection>
      </NavMobileScrollable>
    </Container>
  );
};

type ContainerProps = {
  primaryColor: string;
};

const Container = styled.div<ContainerProps>`
  color: ${props => props.primaryColor};
`;

const NavFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3; /* above film tiles in PromoFeaturedFilmsSection */
  overflow: hidden;
`;

const MubiLogoFixed = styled.div`
  @media (max-width: ${props => props.theme.mqNew.desktop}) {
    transition: opacity 0.6s;
    opacity: 0;
    display: none;

    &.appear-enter,
    &.appear-enter-done,
    &.appear-exit {
      display: block;
    }
    &.appear-enter-active,
    &.appear-enter-done {
      opacity: 1;
    }
  }
`;

const NavMobileScrollable = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4; /* must be higher than NavFixed */

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

type ContentProps = {
  showSecondaryContent?: boolean;
};

const Content = styled.div<ContentProps>`
  position: relative;
  height: 87px;
  display: flex;
  align-items: center;
  border-bottom: ${props =>
    props.showSecondaryContent ? '1px solid rgba(255, 255, 255, 0.3)' : 'none'};
`;

const SecondaryContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  transition: opacity 0.6s;
  opacity: 0;
  display: none;

  &.appear-enter,
  &.appear-enter-done,
  &.appear-exit {
    display: flex;
  }
  &.appear-enter-active,
  &.appear-enter-done {
    opacity: 1;
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    justify-content: space-between;
    margin-left: 30px;
    padding: 8px 0;
  }
`;

const PartnerLogoContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding-left: 30px;
  }
`;

const PartnerLogo = styled(ImageBase)`
  max-height: 100%;
  max-width: 130px;
`;

const Copy = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: none;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: block;
  }
`;

const SecondaryContentRight = styled.div`
  display: flex;
  align-items: center;
`;

const MobileLocaleNotice = styled.div`
  display: block;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

const DesktopLocaleNotice = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: block;
  }
`;

export default PromoHeader;
