import { color as themeColors } from '@app/themes/mubi-theme';

import Svg from '@app/components/icons/Svg';

type WidthColorProps = {
  width?: string;
  color?: string;
};

export const Airplay = ({ width = '60px' }: WidthColorProps) => (
  <Svg viewBox="62.382 32.03 59.1 52.6" width={width}>
    <clipPath id="b">
      <path
        d="M81 88.8c.4.5.4 1.3-.1 1.7-.2.2-.5.3-.8.3H44.9c-.7 0-1.2-.5-1.2-1.2 0-.3.1-.6.3-.8l17.5-20.1c.5-.6 1.3-.6 1.9-.1l.1.1L81 88.8zm-4.1-11.1-2.8-3.3h10.5c.9.1 1.7-.1 2.5-.4.5-.3 1-.7 1.2-1.2.4-.8.5-1.7.4-2.5V45.8c.1-.9-.1-1.7-.4-2.5-.3-.5-.7-1-1.2-1.2-.8-.4-1.7-.5-2.5-.4h-44c-.9-.1-1.7.1-2.5.4-.5.3-1 .7-1.2 1.2-.4.8-.5 1.7-.4 2.5v24.4c-.1.9.1 1.7.4 2.5.3.5.7 1 1.2 1.2.8.4 1.7.5 2.5.4h10.5l-2.8 3.3h-6.7c-3 0-4-.3-5-.9-1.1-.6-1.9-1.4-2.5-2.5-.6-1.1-.9-2.1-.9-5V46.7c0-3 .3-4 .9-5.1.6-1.1 1.4-1.9 2.5-2.5 1.1-.6 2.1-.9 5-.9h42.2c3 0 4 .3 5.1.9 1.1.6 1.9 1.4 2.5 2.5.6 1.1.9 2.1.9 5.1v22.5c0 3-.3 4-.9 5-.6 1.1-1.4 1.9-2.5 2.5-1.1.6-2.1.9-5.1.9l-6.9.1z"
        transform="matrix(1, 0, 0, 1, 0, 0)"
        overflow="visible"
      />
    </clipPath>
    <g clipPath="url(#b)" transform="matrix(1, 0, 0, 1, 29.181641, -6.170341)">
      <defs>
        <path id="c" d="M10.1 10.5H115v104.9H10.1z" />
      </defs>
      <clipPath id="d">
        <path
          d="M10.1 10.5H115v104.9H10.1z"
          transform="matrix(1, 0, 0, 1, 0, 0)"
          overflow="visible"
        />
      </clipPath>
      <g clipPath="url(#d)">
        <path d="M24.8 30.1h75.4v68.8H24.8z" />
      </g>
    </g>
  </Svg>
);

export const Chromecast = ({ width = '22px' }: WidthColorProps) => (
  <Svg viewBox="1 3 22 18" width={width}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M1 18v3h3c0-1.66-1.34-3-3-3Zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7Zm0-4v2a9 9 0 0 1 9 9h2c0-6.08-4.93-11-11-11Zm20-7H3c-1.1 0-2 .9-2 2v3h2V5h18v14h-7v2h7c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2Z"
        fill="#000"
      />
    </g>
  </Svg>
);

export const DevicesIcon = ({
  color = themeColors.white,
  width = '28px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 563 210" width={width}>
    <g fill="none" fillRule="evenodd" transform="translate(3 3)">
      <circle cx="64.5" cy="177.5" fill={color} r="3.5" />
      <circle cx="161.5" cy="150.5" fill={color} r="3.5" />
      <g stroke={color} strokeWidth="5" className="svg-stroke">
        <rect height="55" rx="8" width="81" y="149" />
        <path d="M40 149v-47a8 8 0 018-8h122a8 8 0 018 8v94a8 8 0 01-8 8H66M135 93V8a8 8 0 018-8h270a8 8 0 018 8v52m-43.34 129H179" />
        <rect height="128" rx="8" width="183" x="370" y="61" />
        <path
          d="M231.5 203.415h98.273M367.5 203.415h189.863"
          strokeLinecap="round"
        />
      </g>
    </g>
  </Svg>
);
